import React, { useState } from "react";

// Search component
const Searchbox = ({ handleSearch }) => {

    const [searchTerm, setSearchTerm] = useState("");

    const handleChange = (e) => { setSearchTerm(e.target.value); };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSearch(searchTerm);
    };

    return (
        <form onSubmit={handleSubmit} className="m-4 flex flex-col">
            <section className="flex">
                <input
                    type="text"
                    placeholder="Birşeyler ara..."
                    value={searchTerm}
                    onChange={handleChange}
                    className="flex w-full mb-2 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-primary focus:ring-opacity-50"
                />
            </section>
        </form>


    );
};

export default Searchbox;