import React, {useState } from "react";
import { Searchbox } from "./components";
import { searhPost } from "../../services/post-methods";
import GetPost from "../post/get-post/";


// Search page
const SearchPage = () => {

  
  
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = async (searchTerm) => {

    await searhPost(searchTerm).then((_searchResults) => {

      setSearchResults(_searchResults);

    });

  };

  return (
    <div className="container mx-auto">
      <Searchbox handleSearch={handleSearch} />
      <section className="sr-layout">
        
        <div className="sr-layout flex-col w-full px-2 bg-white">
    
            <ul className="space-y-2 m-0 p-0">
    
                {searchResults.map((post, index) => <GetPost key={post.docId} post={post} />)}
    
            </ul>
    
        </div>
    
    </section>
    </div>
  );
};

export default SearchPage;