// EmailVerificationCheck.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/auth-context';
import { auth } from '../../services/firebase-config';
import { sendEmailVerification } from "firebase/auth";
import { useNavigate } from 'react-router';

const CheckEmail = () => {

	const { user } = useAuth();
	const [isEmailVerified, setIsEmailVerified] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (user) {
			user.reload().then(() => {
				setIsEmailVerified(user.emailVerified);
			});
		} else { setIsEmailVerified(false); }

		if (user && user.emailVerified) { navigate("/"); }

	}, [user, navigate]);

	const handleResendVerification = () => {
		const user = auth.currentUser;

		if (user && !user.emailVerified) {
			sendEmailVerification(user)
				.then(() => alert('Doğrulama e-postası gönderildi!'))
				.catch(error => alert('Hata oluştu: ', error.message));
		}
	};

	return (
		<div className="flex flex-col items-center justify-center h-full">
			<div className="p-6 bg-white rounded-lg w-80 text-center">
				<h1 className="text-2xl font-semibold text-gray-800 mb-4">
					E postanı Doğrula
				</h1>
				{isEmailVerified === null ? (
					<p className="text-gray-500">Kontrol ediliyor...</p>
				) : isEmailVerified ? (
					<p className="text-green-600 font-semibold">Email adresiniz doğrulanmış!</p>
				) : (
					<div>
						<p className="text-red-600 font-semibold mb-4">Email adresiniz doğrulanmamış.</p>
						<button
							onClick={handleResendVerification}
							className="px-6 py-4 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-200"
						>
							Doğrulama e-postası gönder
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default CheckEmail;
