import React, { useEffect, useState } from "react";
import GetPost from "../post/get-post";
import { getAllPost } from "../../services/post-methods";
import { useAuth } from "../../context/auth-context";
import LoadingComponent from "../../components/loading";
import { useNavigate } from "react-router";


export default function Profile() {

    const authContext = useAuth();

    const navigate = useNavigate();

    const [usersPost, setUsersPost] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        (async () => {

            setIsLoading(true);

            await getAllPost().then((posts) => {

                setUsersPost(posts.filter(post => post.uid === authContext.user.uid));

                setIsLoading(false);

            }).catch((error) => { throw new Error(error.message); });


        })()

    }, [authContext, navigate]);


    return (

        isLoading ? <LoadingComponent /> :


            <section className="sr-layout">

                <div className="sticky top-0 home-chart h-full border-b-2">

                    <div className="header"></div>

                    <div className="profile">

                        <img src={authContext.userData?.photoURL || "https://www.gravatar.com/avatar/0?d=mp"} alt="Sarıköy Gençliği" />

                    </div>

                    <div className="content my-0">

                        <h3 className="title">{authContext.userData?.displayName}</h3>

                        <p>{authContext.userData?.bio}</p>

                    </div>

                    
                    <div className="social">
                        {/* eslint-disable jsx-a11y/anchor-has-content */}
                        {
                            authContext.userData.social.twitter &&
                            <a className="fab fa-twitter" href={"https://www.x.com/" + authContext.userData?.social?.twitter}></a>
                        }

                        {
                            authContext.userData.social && 
                            <a className="fab fa-facebook" href={"https://www.facebook.com/" + authContext.userData?.social?.facebook}></a>
                        }

                        {
                            authContext.userData.social && 
                            <a className="fab fa-instagram" href={"https://www.instagram.com/" + authContext.userData?.social?.instagram}></a>
                        }

                    </div>

                </div>

                <div className="sticky flex-col px-2 bg-white min-h-screen">

                    <ul className="space-y-2 m-0 p-0">

                        {usersPost && usersPost.map((post) => <GetPost key={post.docId} post={post} />)}

                    </ul>

                </div>

            </section>
    )

}