import React, { useState } from "react";
import WellcomePost from "./post";

const LocationVerify = () => {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);
  const [isVerified, setIsVerified] = useState(false);

  const handleVerifyLocation = () => {
    setError(null);
    setIsVerified(false);

    if (!navigator.geolocation) {
      setError("Geolocation is not supported by your browser.");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude });

        // Belirli bir konumu kontrol etmek (örneğin, ofisinizin koordinatları)
        const referenceLocation = { latitude: 41.02374113028122, longitude: 28.68903145027297 }; // Empire State Building
        const isValid = isWithinRadius(
          latitude,
          longitude,
          referenceLocation.latitude,
          referenceLocation.longitude,
          1000 // 1000 metre (1 km) yarıçap
        );

        setIsVerified(isValid);
        if (isValid) {
          alert("Konum doğrulandı.");
        } else {
          alert("Konum doğrulanamadı.");
        }
      },
      (err) => {
        setError("Kullanıcının konumu alınamadı: " + err.message);
      }
    );
  };

  // Basit bir mesafe hesaplama fonksiyonu (Haversine formülü)
  const isWithinRadius = (lat1, lon1, lat2, lon2, radius) => {
    const toRad = (value) => (value * Math.PI) / 180;

    const R = 6371e3; // Dünya'nın yarıçapı (metre cinsinden)
    const φ1 = toRad(lat1);
    const φ2 = toRad(lat2);
    const Δφ = toRad(lat2 - lat1);
    const Δλ = toRad(lon2 - lon1);

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Mesafe (metre cinsinden)
    return distance <= radius;
  };

  return (
    <div className="inline-flex flex-col items-center justify-center h-full ">
      <div className="bg-white h-full shadow-md rounded-lg p-6 max-w-md w-full text-center">
		<WellcomePost/>
        <button
          onClick={handleVerifyLocation}
          className="bg-blue-500 text-white m-6 p-4 rounded-lg place-self-end self-end w-full"
        >
          Konumu Doğrula
        </button>
        
      </div>
    </div>
  );
};

export default LocationVerify;
