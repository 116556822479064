import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore, collection } from "firebase/firestore/lite";

const firebaseConfig = {
	apiKey: "AIzaSyBHxNYruJFJ7ADa41-G3CMZoGBzzAqUPdk",
	authDomain: "sarikoygencligi.com",
	projectId: "sarikoygencligi-328f5",
	storageBucket: "sarikoygencligi-328f5.appspot.com",
	messagingSenderId: "283811991737",
	appId: "1:283811991737:web:a5cc7eae0fcc2a42218b68",
	measurementId: "G-1SBRG2VKC9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// Koleksiyon referansları
const userCollectionRef = collection(db, "user");
const postCollectionRef = collection(db, "post");
const messageCollectionRef = collection(db, "messages");

export {
	app, // Firebase app
	auth, // Firebase auth
	db, // Firestore database
	storage, // Firebase storage
	userCollectionRef, // User collection references
	postCollectionRef, // Post collection references
	messageCollectionRef, // Message collection references
};
