import React, { useEffect, useState } from "react";
import { getAllUsers } from "../../services/user-methods";
import { Link } from "react-router-dom";

export default function UserList() {
    
    const [usersData, setUsersData] = useState([]);

    

    useEffect(() => {

        (async () => {
            
            await getAllUsers()
            
            .then((response) => { setUsersData(response); });
        
        })()
    
    }, []);

    return (
        <ul className="space-y-2 m-0 p-0">

            {usersData.map((userData) => {
                return (
                    <li key={userData.uid} className="flex items-center justify-between p-2 border-b border-gray-200">
                        <div className="flex items-center space-x-2">

                            <Link to={`/${userData.username}`} className="flex items-center">
                                <div className="flex-shrink-0 w-12 h-12 bg-gray-200 rounded-full">
                                    <img src={userData?.image || "https://www.gravatar.com/avatar/0?d=mp"} alt="" className="w-12 h-12 bg-gray-200 rounded-full" />
                                </div>
                            </Link>
                            <div>
                                <div className="font-semibold">{userData?.displayName}</div>
                                <div className="font-semibold text-gray-500">{userData?.username}</div>
                            </div>
                        </div>
                    </li>
                )

            })}

        </ul>
    )

}