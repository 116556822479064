import { Outlet } from "react-router-dom";
import React from "react";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { PathProvider } from "../context/path-context";
import { useAuth } from "../context/auth-context";
import LoadingComponent from "../components/loading";
import useAuthRedirect from "../hook/redirect";


export default function Root() {

	const authContext = useAuth();

	useAuthRedirect();
	
	return (

		authContext.isLoading ? <LoadingComponent /> :

		<PathProvider>

			{authContext.user && <Header />}

			<main id="main" className="sr-layout my-14">
				<Outlet />
			</main>

			{authContext.user && <Footer />}

		</PathProvider>
	);
}