import { createBrowserRouter } from "react-router-dom";
import React from "react";
import Root from "../pages/root";
import Home from "../pages/home";
import SetPost from "../pages/post/set-post";
import Profile from "../pages/user/profile";
import SetProfile from "../pages/user/set-profile";
import SearchPage from "../pages/search";
import UserList from "../components/user-list";
import ErrorPage from "../pages/error/";
import UserProfile from "../pages/user/get-user-by-uid";
import SignUpPage from "../pages/auth/signup";
import Login from "../pages/auth/login";
import CheckEmail from "../pages/auth/verify-email";
import LocationVerify from "../pages/location/verify";


const browserRouter = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Home />,
                errorElement: <ErrorPage />,
            },
            {
                path: "/set-post",
                element: <SetPost/>,
                errorElement: <ErrorPage />,
            },
            {
                path: "/profile",
                element: <Profile />,
                errorElement: <ErrorPage />,
            },
            {
                path: "/set-profile",
                element: <SetProfile />,
                errorElement: <ErrorPage />,
            },
            {
                path: "/search",
                element: <SearchPage/>,
                errorElement: <ErrorPage />,
            },
            {
                path: "/users",
                element: <UserList/>,
                errorElement: <ErrorPage />,
            },
            {
                path: "/:username",
                element: <UserProfile/>,
                errorElement: <ErrorPage />,
            },
            {
                path: "/signup",
                element: <SignUpPage/>,
                errorElement: <ErrorPage />,
            },
            {
                path: "/login",
                element: <Login/>,
                errorElement: <ErrorPage />,
            },
            {
                path: "/check-email",
                element: <CheckEmail/>,
                errorElement: <ErrorPage />,
            },
            {
                path: "/location",
                element: <LocationVerify/>,
                errorElement: <ErrorPage/>
            }
        ]
    }
]);

export default browserRouter;
