import React, { useState } from 'react';

export default function MyModal({content}) {

    const [showModal, setShowModal] = useState(true);

    const toggleModal = () => setShowModal(!showModal);

    // Bu, modal'ın dışına tıklama olayını yakalayacak
    const handleBackdropClick = (e) => {
		toggleModal();
        // if (e.target === e.currentTarget) { toggleModal(); }
    };

    if (!showModal) {
        return null; // Eğer modal kapalıysa, hiçbir şey render etme
    }

    return (
        <div className="fixed z-50 inset-0 overflow-y-auto" onClick={handleBackdropClick}>
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 sm:p-0">
                <div className="fixed -z-10 bg-opacity-75 bg-gray-700 top-0 left-0 w-full h-full"></div>

                <div className="bg-white rounded-lg shadow-xl overflow-auto max-w-md mx-auto w-full">
                    <div className="p-4">
                        <h1 className="text-gray-900 text-xl font-bold mb-3">{content.title}</h1>

                        <p className='text-gray-900 my-4'>{content.message}</p>

                        <button onClick={toggleModal} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Kapat
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}