import React, { useEffect, useState } from 'react';
import { AiOutlineSend } from "react-icons/ai";
import { BsCardImage } from "react-icons/bs";
import { useAuth } from '../../../context/auth-context';
import { useLocation, useNavigate } from 'react-router';
import { insertDocument, updateDocument } from '../../../services/post-methods';


const SetPost = () => {
	
	const authContext = useAuth();

	const location = useLocation();

	const navigate = useNavigate();

	const [userData] = useState(authContext.userData);

	const [content, setContent] = useState(location.state ? location.state.post.content : "");
	
	const [post, setPost] = useState(location.state ? location.state.post : {
		
		uid : authContext.user.uid,
		content,
		timestamp: new Date(),
		view_count: 0,
		view_users: [],
		like_count: 0,
		like_users: [],
		dislike_users: [],
		dislike_count: 0,
		complain: [],
		image: { url: "https://picsum.photos/640/360", alt: content }
	
	});

	const handleChange = (e) => { setContent(e.target.value); };

	useEffect(() => {

        if(content !== post.content) {setPost({ ...post, content, timestamp: new Date() })};
	
	}, [content, post, authContext, navigate]);

	const handleSubmit = (e) => {
		
		e.preventDefault();

		if (!post.content) { return; };
		
		post.docId ? updateDocument(post.docId, post) : insertDocument(post);

		setContent("");

	};

	return (

		<form onSubmit={handleSubmit} className="post bg-gray-100 rounded-lg m-3">

			<header className="flex justify-between items-center bg-gray-200 p-2 rounded-tr-md rounded-tl-md">
				<div className="post-info flex items-center">
					<img src={userData?.photoURL || "https://www.gravatar.com/avatar/0?d=mp"} alt={userData?.displayName} className="w-10 h-10 rounded-full mr-2" />
					<span className="text-gray-500">
						{userData?.displayName}<br />
						{new Date().toLocaleDateString()}
					</span>
				</div>
			</header>

			<textarea
				className="w-full h-20 resize-none  focus:outline-none p-2 bg-gray-100"
				placeholder="Ne düşünüyorsun?"
				value={content}
				onChange={handleChange}
			></textarea>

			<footer className="flex justify-between items-center bg-gray-200 rounded-br-md rounded-bl-md">

				<div className="post-info flex items-center">


					<button className="flex items-center text-gray-500 text-gray p-4 rounded-md">
						<BsCardImage className='text-2xl' />
					</button>

				</div>

				<div className="menu flex self-end items-center text-gray-500">

					<button type="submit" className="flex items-center text-gray-500 text-gray p-4 rounded-md">
						<AiOutlineSend className='text-2xl' />
					</button>

				</div>

			</footer>

		</form>
	);
}

export default SetPost;