import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/auth-context';


function useAuthRedirect() {

	const authContext = useAuth();

	const location = useLocation();

	const navigate = useNavigate();	

	useEffect(() => {

		if (authContext.isLoading) { return; }

		// Giriş yapmadığında izin verilen rotalar		
		const exemptRoutes = ['/signup', '/login'];

		if (exemptRoutes.includes(location.pathname)) { return; }

		if (authContext.user) {

			// giriş yaptığnda izin verilen rotalar
			const exemptRoutesWithLogin = ['/set-profile', "/profile", '/check-email', "/location"];

			if (exemptRoutesWithLogin.includes(location.pathname)) { return; }
			
			if (!authContext.user.emailVerified) { navigate("/check-email"); return; }
			
			else if (!authContext.userData.username) { navigate("/set-profile"); return; }

			// else if (!authContext.userData.location) { navigate("/location"); return; }
		
		} else { navigate("/signup"); return; }

	}, [authContext, navigate, location.pathname]);
}

export default useAuthRedirect;
