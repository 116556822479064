import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const PathContext = createContext();

const getPageName = (path) => {
    
    const paths = {
        "/": "Sarıköy Gençliği",
        "/login": "Giriş Yap",
        "/signup": "Kayıt ol",
        "/set-post": "Gönderi düzenle",
        "/create-post": "Gönderi Oluştur",
        "/profile": "Profil",
        "/set-profile": "Profilini Düzenle",
        "/users": "Tüm kullanıcılar",
        "/search": "Gönderi ara",
        "/check-email": "Email Doğrula",     
    };

    if(paths.hasOwnProperty(path)) return paths[path] || "404";

    else return path.split("/")[1] || "404";
};

export function PathProvider({ children }) {
    
    const location = useLocation();
    
    const [pathName, setPathName] = useState(getPageName(location.pathname));
    
    const value = { pathName, setPathName };
    
    useEffect(() => { setPathName(getPageName(location.pathname)) }, [location.pathname]);

    return (
        <PathContext.Provider value={value}>
            {children}
        </PathContext.Provider>
    );
}

export function usePathContext() { return useContext(PathContext); }
