import React, { createContext, useContext, useEffect, useState } from "react";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from "../services/firebase-config";
import { getUserDataByUid } from "../services/user-methods";

const AuthContext = createContext();

export function AuthProvider({ children }) {

	const [user, setUser] = useState(null);

	const [userData, setUserData] = useState(null);

	const [isLoading, setIsLoading] = useState(true);

	const [error, setError] = useState(null);

	const handleLogout = async () => {
		
		setIsLoading(true);
		
		await signOut(auth)

		.then(() => { setUser(null); setUserData(null); })
		
		.catch((error) => { setError(error.message); });
		
		setIsLoading(false);
	
	};

	useEffect(() => {
		
		onAuthStateChanged(auth, async (_user) => {
			
			try {
				
				setIsLoading(true);
				
				setUser(_user);
				
				setUserData(_user ? await getUserDataByUid(_user.uid) : null);
				
				setIsLoading(false);
				
			} catch (error) { setError(error.message); }
			
			
		});
		
	
	}, [user]);

	const value = { user, userData, isLoading, error, handleLogout };

	return (<AuthContext.Provider value={value}>{children}</AuthContext.Provider>);
}

export function useAuth() { return useContext(AuthContext); }
