import { NavLink } from "react-router-dom";
import { FiHome, FiEdit, FiSearch } from "react-icons/fi";
import React from "react";

export const Footer = ({ user, isLoading }) => {

	return (
		<footer id="footer" className="bg-gray-100 border-t-2">
			<nav className="flex justify-evenly w-full">
				<NavLink to="/" className="navlink py-4 icon">
					<FiHome className="react-fi-navlink" />
				</NavLink>

				<NavLink to="/set-post" className="navlink py-4 icon">
					<FiEdit className="react-fi-navlink" />
				</NavLink>

				<NavLink to="/search" className="navlink py-4 icon">
					<FiSearch className="react-fi-navlink" />
				</NavLink>
			</nav>
		</footer>
	);
};
