import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import browserRouter from "./routers";

import "./styles/main.css";
import "./styles/apply.css";
import "tailwindcss/tailwind.css";
import { AuthProvider } from "./context/auth-context";
ReactDOM.createRoot(document.getElementById("root")).render(

	<AuthProvider>
			<RouterProvider router={browserRouter}></RouterProvider>
	</AuthProvider>
	
);
