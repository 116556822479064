import React from "react";


const WellcomePost = ({ post }) => {

    return (

        <article className="post my-2 flex flex-col bg-gray-100 rounded-md ">

            <header className="flex justify-between items-center p-1 rounded-tr-md rounded-tl-md bg-gray-200 text-gray-800">
                <div className="post-info flex items-center">
                    <span className="text-gray-900 text-sm">
                    </span>
                </div>
            </header>

            <section className="">
                <span className="flex px-2 py-6 bg-gray-100">
                    Selam. tek seferlik. Konum bilgine ihtiyacımız var.
                    bunu hesabını doğrulamak için kullanacağız.
                    Dorulamak için Belirlenen yerlerden birinde olman gerekli.
                    Buralar: Dernek binası ve köy olarak belirlendi.
                    Bunu nedeni Uygulama içerisinde köy halkı dışındakileri istemiyoruz.
                    Köye yada derneğe girebilen Uygulamaya da girebilir 🙂
                </span>
            </section>



            <footer className="flex justify-between items-center rounded-br-md rounded-bl-md bg-gray-200">

                <div className="post-info flex items-center">

                </div>

                <div className="menu flex self-end items-center text-gray-400">

                </div>

            </footer>

        </article>
    );
};

export default WellcomePost;
