import React, { useState } from "react";
import { useAuth } from "../../context/auth-context";
import { updateUserDocument } from "../../services/user-methods";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// Validation schema using Yup
const validationSchema = Yup.object({

    displayName: Yup.string()
        .required("Görünür isim alanı zorunludur.")
        .max(50, "Görünür isim en fazla 50 karakter olmalıdır."),

    username: Yup.string()
        .required("Kullanıcı adı alanı zorunludur.")
        .max(20, "Kullanıcı adı en fazla 20 karakter olmalıdır.")
        .transform((value) => value?.toLowerCase()),

    bio: Yup.string().max(200, "Biyografi en fazla 200 karakter olmalıdır."),
    
    twitter: Yup.string().max(20).transform((value) => value?.toLowerCase()),

    facebook: Yup.string().max(20).transform((value) => value?.toLowerCase()),

    instagram: Yup.string().max(20).transform((value) => value?.toLowerCase()),
});

export default function SetProfile() {
    const [activeAccordion, setActiveAccordion] = useState("general");
    const authContext = useAuth();

    const toggleAccordion = (section) => {
        setActiveAccordion((prev) => (prev === section ? null : section));
    };

    return (
        <section className="flex flex-col h-full">
            <Formik
                initialValues={{
                    displayName: authContext.userData?.displayName || "",
                    username: authContext.userData?.username || "",
                    bio: authContext.userData?.bio || "",
                    twitter: authContext.userData?.social?.twitter || "",
                    facebook: authContext.userData?.social?.facebook || "",
                    instagram: authContext.userData?.social?.instagram || "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    const data = {
                        bio: values.bio,
                        displayName: values.displayName,
                        username: values.username.toLowerCase(),
                        social: {
                            twitter: values.twitter,
                            facebook: values.facebook,
                            instagram: values.instagram,
                        },
                    };

                    

                    await updateUserDocument(authContext.user.uid, data)

                    .catch((error) => { throw new Error(error); })

                    .finally(() => { setSubmitting(false); });
                }}
            >
                {({ isSubmitting }) => (
                    <Form className="flex flex-col justify-start w-full h-full max-h-full overflow-auto">
                        {/* General Information Accordion */}
                        <div className="border rounded mb-4">
                            <div
                                className="p-4 bg-gray-200 cursor-pointer"
                                onClick={() => toggleAccordion("general")}
                            >
                                Genel Bilgiler
                            </div>
                            {activeAccordion === "general" && (
                                <div className="grow px-4">
                                    <label className="block mt-4 mb-2 text-sm">Görünür İsim</label>
                                    <Field
                                        type="text"
                                        name="displayName"
                                        placeholder="Adın soyadın"
                                        className="w-full p-2 border rounded"
                                    />
                                    <ErrorMessage name="displayName" component="div" className="text-red-500" />

                                    <label className="block mt-4 mb-2 text-sm">Kullanıcı Adı</label>
                                    <Field
                                        type="text"
                                        name="username"
                                        placeholder="Kullanıcı adınız"
                                        className="w-full p-2 border rounded"
                                    />
                                    <ErrorMessage name="username" component="div" className="text-red-500" />

                                    <label className="block mt-4 mb-2 text-sm">Biyografin</label>
                                    <Field
                                        as="textarea"
                                        name="bio"
                                        placeholder="Biyografiniz: en fazla 200 karakter"
                                        className="w-full p-2 border rounded py-4"
                                    />
                                    <ErrorMessage name="bio" component="div" className="text-red-500" />
                                </div>
                            )}
                        </div>

                        {/* Social Media Accordion */}
                        <div className="border rounded mb-4">
                            <div
                                className="p-4 bg-gray-200 cursor-pointer"
                                onClick={() => toggleAccordion("social")}
                            >
                                Sosyal Medya Hesapları
                            </div>
                            {activeAccordion === "social" && (
                                <div className="grow p-4">
                                    <label className="block mt-4 mb-2 text-sm">Twitter</label>
                                    <Field
                                        type="text"
                                        name="twitter"
                                        placeholder="Twitter URL'niz"
                                        className="w-full p-2 border rounded"
                                    />
                                    <ErrorMessage name="twitter" component="div" className="text-red-500" />

                                    <label className="block mt-4 mb-2 text-sm">Facebook</label>
                                    <Field
                                        type="text"
                                        name="facebook"
                                        placeholder="Facebook URL'niz"
                                        className="w-full p-2 border rounded"
                                    />
                                    <ErrorMessage name="facebook" component="div" className="text-red-500" />

                                    <label className="block mt-4 mb-2 text-sm">Instagram</label>
                                    <Field
                                        type="text"
                                        name="instagram"
                                        placeholder="Instagram URL'niz"
                                        className="w-full p-2 border rounded"
                                    />
                                    <ErrorMessage name="instagram" component="div" className="text-red-500" />
                                </div>
                            )}
                        </div>

                        <button
                            type="submit"
                            className=" w-full p-4 bg-blue-500 text-white rounded self-end"
                            disabled={isSubmitting}
                        >
                            Gönder
                        </button>
                    </Form>
                )}
            </Formik>
        </section>
    );
}
