import { NavLink, useLocation, useNavigate, } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import React from "react";
import MenuContainer from "./main-menu";
import { usePathContext } from "../../context/path-context";

export const Header = () => {
    const pathContext = usePathContext();
    const location = useLocation();
    const navigate = useNavigate();
    return (

        <header id="header" className="bg-gray-100 border-b-2">
            <nav className="flex justify-around w-full">

                <div className="">
                    {
                        <NavLink className="navlink p-4 self-start place-self-start justify-self-start" >
                            <FiChevronLeft onClick={() => { location.pathname !== "/" && navigate(-1) }} className="react-fi-navlink" />
                        </NavLink>
                    }
                </div>

                <div>{pathContext.pathName}</div>

                <div>

                    <MenuContainer />

                </div>

            </nav>
        </header>

    );
};