import React, { useEffect, useRef, useState, } from "react";
import { FiMenu } from "react-icons/fi";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../context/auth-context";

const MainMenu = () => {

    const [isVisible, setIsVisible] = useState(false);

    const { handleLogout } = useAuth();

    const menuRef = useRef();

    const handleClick = () => { setIsVisible(!isVisible); };

    const handleLogoutClick = () => { handleLogout(); setIsVisible(false); };

    const handleClickOutside = (e) => { if (!menuRef.current.contains(e.target)) { setIsVisible(false); } };

    useEffect(() => {

        document.addEventListener("click", handleClickOutside);

        return () => document.removeEventListener("click", handleClickOutside);

    }, []);

    return (
        <div className="relative">
            <motion.div ref={menuRef} className="flex flex-col items-center justify-end p-4" >
                <FiMenu size={24} onClick={handleClick} />
            </motion.div>

            {isVisible && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isVisible ? 1 : 0, zIndex: isVisible ? 50 : -1 }}
                    className="fixed bottom-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-auto"
                >

                    <motion.div
                        initial={{ y: "100%" }}
                        animate={{ y: isVisible ? 0 : "100%" }}
                        duration={0.2}
                        transition={{ duration: 0.2, ease: "easeInOut", }}
                        className="fixed bottom-8 left-0 min-h-2/5 w-full bg-white shadow-2xl p-4 mb-4 rounded-t-lg shadow-gray-950 z-auto">
                        <ul className="flex flex-col items-start justify-center h-full">
                            <li className="w-full my-2">
                                <NavLink to="/profile" className="text-lg font-medium flex h-fit w-full p-4">Profile git</NavLink>
                            </li>
                            <li className="w-full my-2">
                                <NavLink to="/set-profile" className="text-lg font-medium flex h-fit w-full p-4">
                                    Profili Düzenle
                                </NavLink>
                            </li>
                            <li className="w-full my-2">
                                <NavLink className="text-lg font-medium flex h-fit w-full p-4" to="/users" >Kullanıcılar</NavLink>
                            </li>
                            <li className="w-full my-2">
                                <NavLink className="text-lg font-medium flex h-fit w-full p-4" onClick={handleLogoutClick} >Çıkış Yap</NavLink>
                            </li>
                        </ul>
                    </motion.div>
                </motion.div>)}
        </div>
    );
};

export default MainMenu;
