import { doc, getDoc, getDocs, addDoc, updateDoc, deleteDoc, query, where } from "firebase/firestore/lite";
import { postCollectionRef } from "./firebase-config";

// get a document from post collection
async function getDocumentById(docId) {
	const postDocRef = doc(postCollectionRef, docId);
	const postDocSnapshot = await getDoc(postDocRef);
	return postDocSnapshot.exists() ? postDocSnapshot.data() : null;
}

async function getDocumentIdByPostId(postId) {

	const _query = query(postCollectionRef, where("post_id", "==", postId));

	const querySnapshot = await getDocs(_query);

	return querySnapshot.size > 0 ? querySnapshot.docs[0].id : null;
}


async function getDocumentByPostId(postId) {

	const _query = query(postCollectionRef, where("post_id", "==", postId));

	const querySnapshot = await getDocs(_query);

	return querySnapshot.size > 0 ? querySnapshot.docs[0] : null;
}

// Get all documents from post collection with localStorage caching
async function getAllPost() {
    
	const cachedPosts = JSON.parse(localStorage.getItem("posts"));

    if (cachedPosts) { return cachedPosts; }
	
	try {
        const querySnapshot = await getDocs(postCollectionRef);
		
		const postsData = querySnapshot.docs.map(doc => doc.data());
		
		localStorage.setItem("posts", JSON.stringify(postsData));
		
		return postsData;

    } catch (error) { throw new Error(error.message); }
}



// get all documents ids from post collection
async function getAllPostIds() {
	const querySnapshot = await getDocs(postCollectionRef);
	return querySnapshot.docs.map((doc) => doc.id);
}

// insert a document to post collection

async function insertDocument(data) {

	localStorage.clear();

	// add a new document to post collection

	return await addDoc(postCollectionRef, data)
	
	.then((docRef) => {

		// update the document id with the new document id

		updateDoc(doc(postCollectionRef, docRef.id), { docId: docRef.id });
	
	});
}

// update a document in post collection
async function updateDocument(docId, newData) {
	localStorage.clear();
	const postDocRef = doc(postCollectionRef, docId);
	return await updateDoc(postDocRef, newData);
}

// delete a document from post collection
async function deleteDocument(docId) {
	
	const postDocRef = doc(postCollectionRef, docId);
	
	await deleteDoc(postDocRef)
	
	.then((response) => { return response })
	
	.catch((error) => { return error })
}


async function searhPost(keyword) {
	try {
		const searchResult = [];

		const _query = query(postCollectionRef, where("content", ">=", keyword));

		// const _query = query(postCollectionRef, where("content", "array-contains", [keyword]));


		const querySnapshot = await getDocs(_query);

		querySnapshot.forEach(doc => { searchResult.push(doc.data()); });

		return searchResult;

	} catch (error) {
		throw new Error(error.message);
	}
}


export {
	getDocumentById, // get a document from post collection
	getDocumentIdByPostId, // get a document id from post collection
	getDocumentByPostId, // get a document from post collection
	getAllPost, // get all documents from post collection
	getAllPostIds, // get all documents ids from post collection
	insertDocument, // insert a document to post collection
	updateDocument, // update a document in post collection
	deleteDocument, // delete a document from post collection
	searhPost // search post
};
